import { Auth as NuxtAuth } from "@nuxtjs/auth-next/dist";
import jwtDecode from "jwt-decode";
import _ from "lodash";
import { GetterTree, ActionTree, MutationTree } from "vuex";
import { DetailsSource } from "~/entities/AVS";
import { RatesArray } from "~/entities/Rates";
import { UserPreset } from "~/entities/Response/FormData";
import { OrderDate, Order } from "~/entities/Response/OrderData";
import SavedAddress from "~/entities/SavedAddress";
import Search from "~/entities/Search";
import Session from "~/entities/Session";
import * as StoreHelper from "~/entities/StoreHelper";
import Trip from "~/entities/Trip";
import User from "~/entities/User";
import { RootState } from "~/store/index";

type RatesData = RatesArray[];

/* This is used to set the nuxt auth-module user type */
declare module "vue/types/vue" {
  interface Vue {
    // @ts-ignore
    $auth: NuxtAuth & {
      user?: User;
    };
  }
}

declare module 'vuex/types/index' {
  interface Store<S> {
    $paypal: any;
  }
}

export const state = () => ({
  user: (state) => state.auth.user,
  userPackages: [] as UserPreset[] | null,
  userAddresses: [] as SavedAddress[] | null,
  orderDates: [] as OrderDate[] | null,
  orders: [] as Order[] | null,

  session: {} as Session,
  timeoutLogout: false,
});

export type UserState = ReturnType<typeof state>;

export const getters: GetterTree<UserState, RootState> = {
  user: (state) => state.user,
  userPackages: (state) => state.userPackages,
  userAddresses: (state) => state.userAddresses,
  orderDates: (state) => state.orderDates,
  orders: (state) => state.orders,

  session: (state) => state.session,
  timeoutLogout: (state) => state.timeoutLogout,
};

export const mutations: MutationTree<UserState> = {
  // user presets
  setUserPackages(state, packages: UserPreset[] | null) {
    state.userPackages = packages;
  },
  setUserAddresses(state, addresses: SavedAddress[] | null) {
    state.userAddresses = addresses;
  },
  // user orders
  setOrderDates(state, orderDates: OrderDate[] | null) {
    state.orderDates = orderDates;
  },
  setOrders(state, orders: Order[] | null) {
    try {
      if (orders) {
        for (let i = 0; i < orders.length; i++) {
          if (!orders[i]?.shipments?.length) {
            orders[i].shipments = null;
          }
        }
      }
      state.orders = orders;
    } catch (e) {
      console.log(e);
    }
  },
  cleanOrders(state) {
    try {
      state.orders = [];
    } catch (e) {
      console.log(e);
    }
  },
  setOrderDetails(state, order: Order) {
    // this is used to update the order details in the store
    try {
      if (state.orders?.length) {
        const index = state.orders?.findIndex(
          (o) => o.orderId === order.orderId
        );
        if (index >= 0) {
          state.orders[index].orderDate = order.orderDate;
          state.orders[index].orderId = order.orderId;
          state.orders[index].po = order.po;
          state.orders[index].shipments = order.shipments;
          state.orders[index].total = order.total;
        }
      }
    } catch (e) {
      console.log(e);
    }
  },
  openOrderDetails(state, order: Order) {
    try {
      if (state.orders?.length) {
        const index = state.orders?.findIndex(
          (o) => o.orderId === order.orderId
        );
        if (index >= 0) {
          state.orders[index].openDetails = !state.orders[index].openDetails;
        }
      }
    } catch (e) {
      console.log(e);
    }
  },

  // user session
  setSession: (state, value) => {
    state.session = value;
  },
  setSessionTime: (state, value) => {
    const session = state.session;
    state.session.startTime = value;
    try {
      if (session && session.sessionType && typeof window !== "undefined") {
        const jsonData = JSON.stringify(session);
        window?.localStorage?.setItem("session", jsonData);
      }
    } catch (e) {
      console.log(e);
    }
  },
  setUser: (state, value) => {
    if (value.dontRefresh) {
      state.session.setUser(value.value, false);
    } else {
      state.session.setUser(value);
    }
  },
  setGuestUser: (state, value) => {
    state.session.setGuestUser(value);
  },
  unsetUser: (state) => {
    state.session.unsetUser();
  },
  unsetGuestUser: (state) => {
    state.session.unsetGuestUser();
  },
  setTimeoutLogout: (state, value) => {
    state.timeoutLogout = value;
  },

  // user searches
  cleanSearches: (state) => {
    state.session?.cleanSearches();
  },
  updateSearch: (state, value) => {
    state.session.getActiveSearch().updateSearchData(value);
  },
  closeSearch: (state, value) => {
    // removes a search
    state.session.removeSearch(value.index);
  },
  archiveSearch: (state, value) => {
    // adds a search
    state.session.addSearch(value);
  },
  updateActiveSearch: (state, value) => {
    state.session.makeActiveSearch(value);
  },
  setSearchProperty: (state, value) => {
    state.session.setSearchProperty(value.index, value.property, value.value);
  },
  reassignActiveSearch: (state) => {
    state.session.reassignActiveSearch();
  },
};

export const actions: ActionTree<UserState, RootState> = {
  // user-bound formData fetchers
  async requestUserPresets({ commit, getters }) {
    const response = {} as {
      userPackages: [] | unknown;
      userAddresses: [] | unknown;
    };
    try {
      if (
        this.getters["user/userPackages"] &&
        !this.getters["user/userPackages"]?.length
      ) {
        commit("setUserPackages", null); // prevent calling the endpoint multiple times by nulling the value for the incoming request
        const userPackages = await (this as any).$axios.$get(
          `${(this as any).$config.apiURL}/api/UserPackages`
        );
        if (userPackages?.value) {
          commit("setUserPackages", userPackages.value);
        }
      }

      if (
        this.getters["user/userAddresses"] &&
        !this.getters["user/userAddresses"]?.length
      ) {
        commit("setUserAddresses", null); // prevent calling the endpoint multiple times by nulling the value for the incoming request
        const userAddresses = await (this as any).$axios.$get(
          `${(this as any).$config.apiURL}/api/Address`
        );
        if (userAddresses?.value) {
          commit("setUserAddresses", userAddresses.value);
        }
      }

      response.userPackages = this.getters["user/userPackages"];
      response.userAddresses = this.getters["user/userAddresses"];
      return response;
    } catch (e) {
      return false;
    }
  },
  async requestUserPackages({ commit, getters }, force: boolean = false) {
    const response = {} as {
      userPackages: [] | unknown;
      userAddresses: [] | unknown;
    };
    try {
      if (force || !this.getters["user/userPackages"]?.length) {
        // force is used to bypass storage and ensure fetch refresh can occur when a new package is added
        const userPackages = await (this as any).$axios.$get(
          `${(this as any).$config.apiURL}/api/UserPackages`
        );
        if (userPackages?.value) {
          response.userPackages = userPackages.value;
          commit("setUserPackages", userPackages.value);
        }
      }
      return this.getters["user/userPackages"];
    } catch (e) {
      return this.getters["user/userPackages"];
    }
  },
  async requestUserAddresses({ commit, getters }, force: boolean = false) {
    try {
      if (force || !this.getters["user/userAddresses"]?.length) {
        // force is used to bypass storage and ensure fetch refresh can occur when a new address is added
        const userAddresses = await (this as any).$axios.$get(
          `${(this as any).$config.apiURL}/api/Address`
        );
        if (userAddresses?.value) {
          commit("setUserAddresses", userAddresses.value);
        }
      }
      return this.getters["user/userAddresses"];
    } catch (e) {
      return this.getters["user/userAddresses"];
    }
  },

  // user orders actions
  async requestOrderDates({ commit, getters }, force: boolean = false) {
    try {
      if (force || !this.getters["user/orderDates"]?.length) {
        const orderDates = await (this as any).$axios.$get(
          `${(this as any).$config.apiURL}/api/Order/dates`
        );
        if (orderDates) {
          commit("setOrderDates", orderDates);
        }
      }
      return this.getters["user/orderDates"];
    } catch (e) {
      return this.getters["user/orderDates"];
    }
  },
  async requestOrders({ commit, getters }, orderDate: OrderDate) {
    try {
      const orders = await (this as any).$axios.$get(
        `${(this as any).$config.apiURL}/api/Order/get?month=${
          orderDate.month
        }&year=${orderDate.year}`
      );
      if (orders) {
        commit("setOrders", orders);
      }
      return this.getters["user/orders"];
    } catch (e) {
      return this.getters["user/orders"];
    }
  },
  async requestOrderDetails({ commit, getters }, order: Order | null) {
    try {
      const response = await (this as any).$axios.$get(
        `${(this as any).$config.apiURL}/api/Order/detail?orderId=${
          order?.orderId
        }`
      ); // this is inefficient, it asks for info that is VERY unlikely to change on a requested refresh. But it is the only way to ensure the data is up to date.
      commit("setOrderDetails", response);
      return this.getters["user/orders"]?.filter(
        (o) => o.orderId === order?.orderId
      );
    } catch (e) {
      return this.getters["user/orders"];
    }
  },
  async requestRecentOrders({ commit, getters }) {
    try {
      const recentOrders = await (this as any).$axios.$get(
        `${(this as any).$config.apiURL}/api/Order/recent?num=3`
      );
      if (recentOrders) {
        return recentOrders;
      }
      return [];
    } catch (e) {
      return [];
    }
  },

  // user authentication actions
  async loginUser({ dispatch, commit }, formData) {
    try {
      const response = await this.$auth.login({ data: formData });
      if (response) {
        const decoded = jwtDecode<User>(response["data"].token);
        this.$auth.$storage.setUniversal("user", new User(decoded));
        localStorage.setItem("jwt", response["data"].token);
        this.$axios.setToken(response["data"].token, "Bearer");
        dispatch("startUser", new User(decoded));
        return true;
      } else return false;
    } catch (e) {
      return false;
    }
  },
  loginGuest({ commit }, formData) {
    try {
      commit("setGuestUser", formData);
      return true;
    } catch (e) {
      return false;
    }
  },
  async registerUser({ dispatch, commit }, formData) {
    try {
      const response = await this.$axios
        .$post(`/api/Authentication/register`, formData)
        .catch(function (error) {
          if (error.response) {
            return error.response.data;
          }
        });
      if (response) {
        if (
          response.constructor === Array &&
          response[0] &&
          response[0].hasOwnProperty("propertyName")
        ) {
          return response;
        }
        // should not start user on register
        // await dispatch('startUser', response && response.constructor == Array ? response[0]: response);
        return response;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  },
  async logoutUser({ commit }) {
    try {
      await this.$auth.logout().finally(() => {
        localStorage.removeItem("jwt");
        this.$auth.$storage.removeUniversal("user");
      });
      window.localStorage.removeItem("session"); // remove session. Should this be a call to a mutation or action to remove session?
      commit("unsetUser");
    } catch (e) {
      console.error(
        "Local storage is not supported, falling back to cookie use"
      );
    }
  },
  async updateUserData({}, user) {
    return await this.$axios.$put(`/api/User`, { ...user });
  },
  async getUserData({ commit }) {
    try {
      let user = await this.$axios.$get(`/api/User`);
      if (user && user.value) {
        user = user.value;
        commit("setUser", user);
        this.$auth.$storage.setUniversal("user", user);
      }
    } catch (e) {
      console.log(e);
    }
  },
  async getUserPaypalToken({ commit }) {
    try {
      const timestamp = new Date().getTime();
      const token = await this.$axios.$get(`/api/paypal/token?_t=${timestamp}`);
      if (token && token.idToken) {
        return token.idToken; 
      }
    } catch (e) {
      console.log(e);
    }
  },
  async forgetPassword({ dispatch, commit }, formData) {
    try {
      const response = await Promise.all([
        this.$axios.$post(`/api/Authentication/forgot-password`, formData), // forgetPassword
      ]);
      return response;
    } catch (e) {
      return false;
    }
  },
  async checkEmail({ commit }, formData) {
    // checks if a given email exists associated to an account
    try {
      await Promise.all([
        this.$axios.$get(
          `/api/Authentication/check-email` +
            (formData.email ? "?email=" + formData.email : "")
        ),
      ]);
      return true;
    } catch (e) {
      return false;
    }
  },
  async resetPassword({}, formData) {
    try {
      const response: any = await Promise.all([
        this.$axios.$post(`/api/Authentication/reset-password`, formData),
      ]);
      return response;
    } catch (e: any) {
      return false;
    }
  },
  async ValidateResetPasswordToken({}, formData) {
    try {
      const response: any = await Promise.all([
        this.$axios.$post(`/api/Authentication/verify-reset-token`, formData),
      ]);
      return response[0];
    } catch (e: any) {
      return false;
    }
  },

  // user session actions
  async startSession({ dispatch, commit }, addUser = true) {
    // init the session object in the store. IF there is a previous session, check if it has searches.
    let jsonData = "";
    let session;
    if (typeof window !== "undefined") {
      session = new Session(undefined, 1);
      const loggedSession = window?.localStorage?.getItem("session");

      // for nuxt auth recover session
      if (this.$auth.check().valid) {
        this.$auth.setUser(this.$auth.$storage.getUniversal("user"));
      }

      if (loggedSession) {
        const data = JSON.parse(loggedSession);
        session.startTime = data.startTime
          ? (data.startTime as number)
          : new Date().getTime();
        if (data.id) {
          session.setID(data.id);
        }
        if (data.searches && data.searches.constructor == Array) {
          session.setSearches(data.searches);
        } else {
          session.setSearches([]);
        }
        commit("setSession", session);
        if (data.user && data.user.id && addUser) {
          await dispatch("startUser", { user: data["user"], onStart: true });
        }
      } else {
        session = new Session();
        const sessionData = window?.sessionStorage?.getItem("searchData");
        if (sessionData) {
          // if there is a previous search from a reload. always false for now
          const data = JSON.parse(sessionData);
          session.setSearches(data);
        } else {
          // sets a search data object here to serve as initial search data in session
          jsonData = JSON.stringify([]);
          // update shipData
          if (jsonData) {
            window?.sessionStorage?.setItem("searchData", jsonData);
          }
          session.setSearches([]);
        }
        session.sessionType = 0;
        commit("setSession", session);
      }
    }
  },
  async startUser({ dispatch, commit }, data) {
    // sets the user into session. used for login.
    const session = this.getters["user/session"];
    if (!(session && session.constructor === Session)) {
      dispatch("startSession", false);
    }
    let user;
    if (data.onStart) {
      user = new User(data.user);
      commit("setUser", { value: user, dontRefresh: true });
    } else {
      user = new User(data);
      commit("setUser", user);
    }
    const searches = await dispatch("getSavedSearches");
    if (searches && searches?.value && searches.value?.savedSearches) {
      commit("cleanSearches"); // clean previous searches
      dispatch("setSavedSearches", searches.value?.savedSearches);
      commit("reassignActiveSearch");
    }
    dispatch("saveSessionStorage", { skipUpdateSearches: true });
  },
  async extendSession({ dispatch, commit }) {
    // calls endpoint to extend the session of the user in backend and sets the updated user data containing the session duration to the session object
    try {
      const response: any = await Promise.all([
        this.$axios.$post(`/api/Authentication/extend-session`, {}),
      ]);
      localStorage.setItem("jwt", response["0"].token);
      const decoded = jwtDecode<User>(response["0"].token);
      this.$auth.$storage.setUniversal("user", new User(decoded));
      this.$axios.setToken(response["0"].token, "Bearer");
      commit("setUser", { value: new User(decoded), dontRefresh: true });
    } catch (e: any) {
      return false;
    }
  },
  // session client-side saving
  async saveSessionStorage({ dispatch, commit }, value) {
    const session = this.getters["user/session"];
    if (session.sessionType) {
      // this session is for a logged user so it belongs in the local storage
      return await dispatch("saveToLocalStorage", value);
    } else {
      // this session is for a logged user so it belongs in the session storage
      return dispatch("saveToSessionStorage", null);
    }
  },
  saveToSessionStorage({ commit }) {
    // save into sessionStorage for guests
    if (typeof window !== "undefined") {
      const session = this.getters["user/session"];
      const jsonData = JSON.stringify(session ? session.searches : []);
      window?.sessionStorage?.setItem("searchData", jsonData);
      return true;
    } else {
      return false;
    }
  },
  async saveToLocalStorage({ dispatch, commit }, value) {
    // save searches to api if all responses where positive, then save into localStorage.
    // skipUpdateSearches is used to prevent searches to be updated during login as they were getting pulled from the savedSearch endpoint and immediately updated.
    let searchesSaved = [];

    if (!value?.skipUpdateSearches)
      searchesSaved = await dispatch("saveSearches");

    if (typeof window !== "undefined" && searchesSaved) {
      const session = this.getters["user/session"];
      if (session) {
        const jsonData = JSON.stringify(session);
        window?.localStorage?.setItem("session", jsonData);
        return true;
      } else {
        return false;
      }
    }
  },

  // user searches actions
  async saveSearches({ commit }): Promise<boolean> {
    // puts a search into the database
    let error = false;
    const session = this.getters["user/session"];
    const activeSearch = session?.getActiveSearch();
    const ownerID = session?.user.getID();
    const config = {
      withCredentials: true,
      Accept: "application/json",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      maxContentLength: 100000000,
      maxBodyLength: 1000000000,
    };

    if (activeSearch) {
      let response;
      // search already exists, needs to update
      if (activeSearch.savedSearchId) {
        response = await (this as any).$axios.$put(
          `/api/SavedSearch/` + activeSearch.savedSearchId,
          StoreHelper.default.getSaveSearchBodyObject(activeSearch, ownerID),
          config
        );
      } else {
        // new search, needs to post it
        response = await (this as any).$axios.$post(
          `/api/SavedSearch`,
          StoreHelper.default.getSaveSearchBodyObject(activeSearch, ownerID),
          config
        );
      }

      if (response && response.value) {
        const savedSearchId = response?.value?.savedSearchId;
        const savedSearchCode = response?.value?.savedSearchCode;
        commit("setSearchProperty", {
          index: activeSearch.id,
          property: "savedSearchId",
          value: savedSearchId,
        });
        commit("setSearchProperty", {
          index: activeSearch.id,
          property: "savedSearchCode",
          value: savedSearchCode,
        });
      } else {
        error = true;
      }
    } else {
      return false;
    }
    return !error;
  },
  async getSavedSearches({ commit }) {
    // retrieves paginated searches from the database
    try {
      return await (this as any).$axios.$get(
        `/api/SavedSearch/paged?skip=0&take=3`
      );
    } catch (e) {
      return false;
    }
  },
  async deleteSavedSearch({ commit }, id) {
    // deletes a search from the database
    try {
      const deleted = await (this as any).$axios.$delete(
        `/api/SavedSearch/` + (id ? id : "")
      );
      if (deleted && deleted.value) {
        return true;
      }
    } catch (e) {
      return false;
    }
  },
  setSavedSearches({ commit }, data) {
    // sets the searches from the database into the session
    if (data) {
      for (let step = 0; step < data.length; step++) {
        const savedSearchId = data[step].savedSearchId;
        const savedSearchCode = data[step].savedSearchCode;
        const search = new Search(step, true);
        search.setSearchData(JSON.parse(data[step].serializedSearchData));
        search.savedSearchId = savedSearchId;
        search.savedSearchCode = savedSearchCode;
        commit("archiveSearch", search);
      }
    }
  },
  updateSearch({ rootGetters, rootState, dispatch, commit }, value) {
    // updates the last search in searches array
    let index = 0;
    const session = this.getters["user/session"];
    const activeSearch = session?.getActiveSearch();
    if (activeSearch) {
      // there are searches in the session and an active search
      index = activeSearch.id;
    } else {
      const search = new Search(index);
      commit("archiveSearch", search);
      commit("updateActiveSearch", search);
    }
    const jsonData = {} as any;
    jsonData.id = index;
    jsonData.step = value.nextStep;
    jsonData.prevStep = value.previousStep;
    jsonData.active = value.active; // supposed to always be true as updates only happen on the active search.
    jsonData.trips = this.getters["ship/trips"];
    jsonData.details = this.getters["ship/details"];
    jsonData.boxes = this.getters["ship/boxes"];
    jsonData.descriptions = this.getters["ship/descriptions"];
    jsonData.units = this.getters["ship/units"];
    jsonData.purpose = this.getters["ship/purpose"];
    jsonData.rates = this.getters["ship/rates"];
    jsonData.selectedRateSpeed = this.getters["ship/selectedRateSpeed"];
    jsonData.selectedRateSpeedIndex =
      this.getters["ship/selectedRateSpeedIndex"];
    jsonData.premiumProtection = this.getters["ship/premiumProtection"];
    jsonData.pickupOption = this.getters["ship/pickupOption"];
    jsonData.subTotal = this.getters["ship/subTotal"];
    commit("updateSearch", jsonData);
    dispatch("saveSessionStorage", null);
  },
  async resumeSearch({ dispatch, commit }, value) {
    // rescue the data from a search and puts it into the ship store
    let searchStep = value.search.step;
    const search = _.cloneDeep(value.search);
    commit("setLoading", true, { root: true });
    // attend restore
    if (searchStep >= 2) {
      searchStep = 2;
      try {
        if (search.trips) {
          commit(
            "ship/setTrips",
            search.trips.map((trip: Trip) => new Trip(trip)),
            { root: true }
          );
          if (search.trips.length && search.trips.length < 2) {
            commit(
              "ship/setShipMode",
              {
                id: 1,
                label: "One-Way",
              },
              { root: true }
            );
          } else if (search.trips.length && search.trips.length === 2) {
            commit(
              "ship/setShipMode",
              {
                id: 2,
                label: "Round-Trip",
              },
              { root: true }
            );
          } else if (search.trips.length && search.trips.length > 2) {
            commit(
              "ship/setShipMode",
              {
                id: 3,
                label: "Multi-City",
              },
              { root: true }
            );
          }
        }
        if (search?.details) {
          const details = _.cloneDeep(search.details);
          for (let index = 0; index < details.length; index++) {
            details[index].from.avsStatus.source = DetailsSource.recentSearches;
            details[index].to.avsStatus.source = DetailsSource.recentSearches;
            if (
              details[index].from.streetAddress ||
              details[index].from.address1
            ) {
              details[index].from.searchType = "full";
            } else {
              details[index].from.searchType = "partial";
            }
            if (details[index].to.streetAddress || details[index].to.address1) {
              details[index].to.searchType = "full";
            } else {
              details[index].to.searchType = "partial";
            }
          }
          commit("ship/setDetailsOnly", details, { root: true });
        }
        if (search?.boxes)
          commit("ship/setBoxes", search.boxes, { root: true });
        if (search?.descriptions)
          commit("ship/setDescriptions", search.descriptions, { root: true });
        if (search?.units)
          commit("ship/setUnits", search.units, { root: true });
        if (search?.purpose)
          commit("ship/setPurpose", search.purpose, { root: true });
        if (search?.premiumProtection)
          commit("ship/setPremiumProtection", search.premiumProtection, {
            root: true,
          });
        if (search?.pickupOption)
          commit("ship/setPickUpOption", search.pickupOption, { root: true });
        // return
        // call rates
        const ratesResponse: RatesData = await dispatch("ship/getRates", null, {
          root: true,
        });
        if (
          ratesResponse &&
          StoreHelper.default.validateUPSRates(ratesResponse)
        ) {
          const filteredRatesResponse =
            StoreHelper.default.filterRatesResponse(ratesResponse);
          commit("ship/setRates", filteredRatesResponse, { root: true });
          commit(
            "ship/setRatesAreValid",
            { event: {}, valid: true },
            { root: true }
          );
          if (search.selectedRateSpeed)
            commit("ship/setSelectedRateSpeed", search.selectedRateSpeed, {
              root: true,
            });
          if (search.selectedRateSpeedIndex)
            commit(
              "ship/setSelectedRateSpeedIndex",
              search.selectedRateSpeedIndex,
              { root: true }
            );
          if (search.subTotal)
            commit("payment/setSubTotal", search.subTotal, { root: true });
          commit("unsetGuestUser", null);
        } else {
          commit("ship/setRatesAreValid", { valid: false }, { root: true });
        }
        // end call rates
      } catch (e) {
        commit(
          "ship/setRatesAreValid",
          { event: e, valid: false },
          { root: true }
        );
      }
    } else {
      try {
        if (search.trips) {
          commit(
            "ship/setTrips",
            search.trips.map((trip: Trip) => new Trip(trip)),
            { root: true }
          );
          if (search.trips.length && search.trips.length < 2) {
            commit(
              "ship/setShipMode",
              {
                id: 1,
                label: "One-Way",
              },
              { root: true }
            );
          } else if (search.trips.length && search.trips.length === 2) {
            commit(
              "ship/setShipMode",
              {
                id: 2,
                label: "Round-Trip",
              },
              { root: true }
            );
          } else if (search.trips.length && search.trips.length > 2) {
            commit(
              "ship/setShipMode",
              {
                id: 3,
                label: "Multi-City",
              },
              { root: true }
            );
          }
        }
        if (search?.details)
          commit("ship/setDetailsOnly", search.details, { root: true });
        if (search?.boxes)
          commit("ship/setBoxes", search.boxes, { root: true });
        if (search?.descriptions)
          commit("ship/setDescriptions", search.descriptions, { root: true });
        if (search?.units)
          commit("ship/setUnits", search.units, { root: true });
        if (search?.purpose)
          commit("ship/setPurpose", search.purpose, { root: true });
        if (search?.rates)
          commit("ship/setRates", search.rates, { root: true });
        if (search?.selectedRateSpeed)
          commit("ship/setSelectedRateSpeed", search.selectedRateSpeed, {
            root: true,
          });
        if (search?.selectedRateSpeedIndex)
          commit(
            "ship/setSelectedRateSpeedIndex",
            search.selectedRateSpeedIndex,
            { root: true }
          );
        if (search?.premiumProtection)
          commit("ship/setPremiumProtection", search.premiumProtection, {
            root: true,
          });
        if (search?.pickupOption)
          commit("ship/setPickUpOption", search.pickupOption, { root: true });
        if (search?.subTotal)
          commit("payment/setSubTotal", search.subTotal, { root: true });
      } catch (e) {
        commit(
          "ship/setRatesAreValid",
          { event: e, valid: false },
          { root: true }
        );
      }
    }
    // if rates is not valid, it should not got to costs, so step is < 2
    const ratesValidity = this.getters["ship/ratesAreValid"];
    if (searchStep > 1 && ratesValidity && !(ratesValidity as any).valid) {
      searchStep = 1;
    }
    // end attend restore
    setTimeout(() => {
      try {
        dispatch("ship/setStep", searchStep, { root: true });
        commit("setLoading", false, { root: true });
      } catch (e) {
        console.log(e);
      }
    }, 1500);
    // make this search the current active search.
    try {
      // commit('reassignSearchesIndexes', {root:true});
      commit("updateActiveSearch", search);
      dispatch("saveSessionStorage", null);
    } catch (e) {
      console.log(e);
    }
  },
  async forgetSearch({ rootGetters, rootState, dispatch, commit }, value) {
    // deletes a search from the session, and if it is a saved search, deletes it from the server
    const session = this.getters["user/session"];
    const search = session?.getSearch(value.index);
    if (session && session.sessionType && search && search.savedSearchId) {
      const savedSearchId = search.savedSearchId;
      const deleted = await dispatch("deleteSavedSearch", savedSearchId);
      if (deleted) {
        commit("closeSearch", value);
        dispatch("saveSessionStorage", null);
      }
    } else {
      commit("closeSearch", value);
      dispatch("saveSessionStorage", null);
    }
  },
};
